// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-service-page-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-job-post-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/templates/job-post.js" /* webpackChunkName: "component---src-templates-job-post-js" */),
  "component---src-templates-tags-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-index-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-examples-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-dashboard-index-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-profile-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/dashboard/profile.js" /* webpackChunkName: "component---src-pages-dashboard-profile-js" */),
  "component---src-pages-login-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-index-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-services-feed-optimization-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/services/feed-optimization.js" /* webpackChunkName: "component---src-pages-services-feed-optimization-js" */),
  "component---src-pages-services-partner-management-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/services/partner-management.js" /* webpackChunkName: "component---src-pages-services-partner-management-js" */),
  "component---src-pages-services-roi-analysis-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/services/roi-analysis.js" /* webpackChunkName: "component---src-pages-services-roi-analysis-js" */),
  "component---src-pages-services-shopping-feeds-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/services/shopping-feeds.js" /* webpackChunkName: "component---src-pages-services-shopping-feeds-js" */),
  "component---src-pages-tags-index-js": () => import("/Users/mike/Documents/workspace/gatsby/wd/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/mike/Documents/workspace/gatsby/wd/.cache/data.json")

